.disclaimer-kill {
  padding-bottom: 0;
  font-weight: normal;
}

.company {
  font-weight: lighter;
}

.image-frame {
  text-align: center;
  margin-bottom: 10px;
}

.black-link {
  color: black !important;
}

.no-decoration-link {
  text-decoration: none !important;
}

.no-decoration-link:hover {
  text-decoration: none !important;
}

.offset {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
}

.news-area {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  padding-left: 30px;
  padding-right: 30px;
}
