.tertiary-project:hover {
  background-color: #393939 !important;
  color: white;
  cursor: pointer;
}

.bx--progress-optional {
  color: silver;
}

.no-decoration-enforce {
  text-decoration: none !important;
}

.no-decoration-enforce:hover {
  text-decoration: none !important;
}
